import style from './Banner.module.css'
import useTranslation from 'next-translate/useTranslation'
import classNames from 'classnames/bind'
import Link from 'next/link'
import { Swipeable } from '../helpers/swipeable'
import { useState } from 'react'
import useAuth from '../hooks/useAuth'

export default function Banner () {
  const c = classNames.bind(style)
  const { t } = useTranslation('main')
  const [visible, setVisible] = useState(true)
  const { isAuthed, isTwoFaEnabled } = useAuth()

  return isAuthed && isTwoFaEnabled ? null : (
    <Swipeable onSwipedDown={() => setVisible(false)} onSwipedUp={() => setVisible(true)} preventDefaultTouchmoveEvent className={c('banner', { hide: !visible })}>
      <section>
        <div>
          <div className={c('quotation')}>&quot;{t('banner-signup-quote1')}&quot;</div>
          <div className={c('author')}>- {t('banner-signup-quote1author')}</div>
        </div>
        <div>
          <Link href='/signup'>
            <button className='goldenshimmer' title={t('banner-signup-button-title')}>{t('banner-signup-button')}</button>
          </Link>
          <span className={c('termsnote')}>{t('banner-signup-subtext')}</span>
        </div>
      </section>
    </Swipeable>
  )
}
