
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import style from './404.module.css'

import { getLayout } from '../components/sg/Layout'
import Header from '../components/Header'
import useTranslation from 'next-translate/useTranslation'
import classNames from 'classnames/bind'
import Banner from '../components/Banner'
import Link from 'next/link'

export default function FourOFour () {
  const { t } = useTranslation('main')
  const c = classNames.bind(style)

  return (
    <>
      <Header showLogo />
      <div className={c('notfound')}>
        <p>
          {t('notfound-title')}
          <br /><br />
          <Link href='/'>{t('notfound-link')}</Link>
        </p>
      </div>
      <Banner />
    </>
  )
}

FourOFour.getLayout = getLayout


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  