import style from './Header.module.css'

import classNames from 'classnames/bind'
import Link from 'next/link'

export default function Header ({ showLogo, title, children }) {
  const c = classNames.bind(style)

  return (
    <header className={c('header')}>
      {showLogo && (
        <Link href='/sg'>
          <a>
            Gold Savings<br />Account
          </a>
        </Link>
      )}
      <h2>{title || ''}</h2>
      {children}
    </header>
  )
}
